import QRCode from 'qrcode';

import { HOST_DOMAIN, HOST_MAIN, HOST_PROTOCOL } from '../conf';

import { isServer } from './const';

// import { decode } from 'he'; // TODO purge
// import { stripHtml as strip } from 'string-strip-html'; // TODO purge
// https://stackoverflow.com/questions/18749591/encode-html-entities-in-javascript
// https://github.com/mathiasbynens/he/issues/64
// https://github.com/wooorm/parse-entities/blob/main/decode-entity.browser.js
// https://github.com/vuejs/vue/commit/a855dd0564a657a73b7249469490d39817f27cf7#diff-c0a2623ea5896a83e3b630f236b47b52
// https://stackoverflow.com/a/13091266/4936667
// https://code-boxx.com/strip-remove-html-tags-javascript/

// TODO version for SSR
// TODO check script injection
let decoder;
const decode = (html: string): string => {
    if (isServer) {
        // TODO hotfix
        return html;
    }

    decoder = decoder || document.createElement('div');
    html = escape(html).replace(/%26/g, '&').replace(/%23/g, '#').replace(/%3B/g, ';');
    decoder.innerHTML = html;

    return unescape(decoder.textContent);
};

// TODO version for SSR
// TODO check script injection
let parser;
const strip = (html: string): string => {
    try {
        parser = parser || new DOMParser();
        const dom = parser.parseFromString(html, 'text/html');
        return dom.body?.textContent;
    } catch (e) {}

    return '';
};

export const UnescapeHtml = (value: string | number): string =>
    decode(value !== null && typeof value !== undefined ? String(value) : '');

export const striptags = (value: string | number): string => strip(UnescapeHtml(value)); /*.result*/

export const stringNotEmpty = (s: string, strip = false): boolean =>
    Boolean(s && (strip ? striptags(s)?.trim() : s?.trim()));

export const leadingZero = (num: number): string => String(num)?.padStart(2, '0');

// TODO ?? wtf
export const applyStylesToClassNameWithImportant = (className: string, styles: CSSProperties): void => {
    setTimeout(() => {
        const curClassName = [].slice.call(document.querySelectorAll(className));
        if (Array.isArray(curClassName) && curClassName.length > 0) {
            curClassName.forEach((curElem) => {
                for (const [key, value] of Object.entries(styles)) {
                    (curElem as HTMLDivElement).style[key] = value;
                }
            });
        }
    }, 10);
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const nop = (): void => {};

export const createSubdomainUrl = (subdomain: string, query?: string): string =>
    `${HOST_PROTOCOL}${subdomain}.${HOST_DOMAIN}${query ? `/${query}` : ''}`;

export const createLandingUrl = (path?: string): string =>
    `${HOST_PROTOCOL}${HOST_MAIN}${path.startsWith('/') ? path : `/${path}`}`;

export const createAccountUrl = (path?: string): string =>
    `${HOST_PROTOCOL}${HOST_MAIN}/account${path ? `/${path}` : ''}`;

export const createEmbedUrl = (resource: string): string => `${HOST_PROTOCOL}${HOST_MAIN}/embed/${resource}`;

export const createFileUrl = (file: string): string => `${HOST_PROTOCOL}${HOST_MAIN}/files/${encodeURIComponent(file)}`;

// TODO replace with nanoid, drop negative value checks
export const tempId = (): number => -1 * Math.floor(1000000000 + Math.random() * 9000000000);

export const parseJSON = <T>(data: string, defaultValue = {}): T => {
    try {
        return JSON.parse(data);
    } catch (e) {
        console.error(data, e);
        return defaultValue as T;
    }
};

export const tuple2Obj = (tuple: Array<[string, PrimitiveValue]>): AnyObject =>
    tuple.reduce((acc, [k, v]) => ((acc[k] = v), acc), {});

export const hasExtension = (name: string, exts: Array<string>): boolean =>
    new RegExp('(' + exts.join('|').replace(/\./g, '\\.') + ')$').test(name);

export const splitNewLine = (text: string): Array<string> => text.split(/(?:\r\n|\r|\n)/).filter((line) => line);

const fullUrlRe = new RegExp(`(http(s):\/\/)?(([^.]+)\.)?${HOST_DOMAIN.replace('.', '\\.')}`);
export const extractSubdomain = (url: string): string => {
    const match = fullUrlRe.exec(url);

    return match ? match[4] : null;
};

export const arrayMove = (arr: Array<any>, from: number, to: number): void => {
    const startIndex = from < 0 ? arr.length + from : from;

    if (startIndex >= 0 && startIndex < arr.length) {
        const endIndex = to < 0 ? arr.length + to : to;

        const [item] = arr.splice(from, 1);
        arr.splice(endIndex, 0, item);
    }
};

export const getMovedArray = (arr: Array<any>, from: number, to: number): Array<any> => {
    const startIndex = from < 0 ? arr.length + from : from;

    if (startIndex >= 0 && startIndex < arr.length) {
        const endIndex = to < 0 ? arr.length + to : to;

        const copyArray = [...arr];

        const [item] = copyArray.splice(from, 1);
        copyArray.splice(endIndex, 0, item);

        return copyArray;
    }

    return arr;
};

export const orderedMoveAndSort = <T extends { id: number }>(
    items: Array<T>,
    sourceId: number,
    targetId: number
): void => {
    const fromIndex = items.findIndex(({ id }) => id === sourceId);
    const toIndex = items.findIndex(({ id }) => id === targetId);

    arrayMove(items, fromIndex, toIndex);
};

export const getOrderedMoveAndSort = <T extends { id: number }>(
    items: Array<T>,
    sourceId: number,
    targetId: number
): Array<T> => {
    const fromIndex = items.findIndex(({ id }) => id === sourceId);
    const toIndex = items.findIndex(({ id }) => id === targetId);

    return getMovedArray(items, fromIndex, toIndex);
};

export const orderedMoveAndSortByIndex = <T extends { order: number }>(
    items: Array<T>,
    fromIndex: number,
    toIndex: number
): void => {
    arrayMove(items, fromIndex, toIndex);

    items.forEach((item, index) => {
        item.order = index;
    });
};

export const getUrlSearchParam = (name: string, search = window.location.search): string => {
    try {
        const urlParams = new URLSearchParams(search);
        return urlParams.get(name);
    } catch (e) {
        console.error(e);
        return null;
    }
};

export const arrDeleteById = <T, I = number>(arr: Array<T & { id: I }>, ids: Array<I>): void => {
    for (const i of ids) {
        const idx = arr.findIndex(({ id }) => id === i);
        if (idx !== -1) arr.splice(idx, 1);
    }
};

export const arrDeleteByValue = <T>(arr: Array<T>, val: T): void => {
    const idx = arr.findIndex((v) => v === val);
    if (idx !== -1) arr.splice(idx, 1);
};

export const shuffleArray = (arr: Array<any>): Array<any> => arr.slice().sort(() => Math.random() - 0.5);

export const pickIds = (arr: Array<{ id: number }>): Array<number> => arr.map(({ id }) => id);

export const updateItemInArray = <T, R = void>(
    array: Array<T & { id: number | string }>,
    itemId: number,
    updateItemCallback: (item: T) => T | R
): Array<T | R> => array.map((item) => (item.id === itemId ? updateItemCallback(item) : item)).filter((a) => !!a);

export const replaceInArray = <T, R = void>(
    array: Array<T & { id: number }>,
    itemId: number,
    item: T & { id: number }
): Array<T | R> => {
    const idx = array.findIndex(({ id }) => id === itemId);

    array[idx] = item;

    return array;
};

export const undefDestroy = <T>(object: AnyObject<T>): AnyObject<T> => {
    for (const key in object) {
        if (object[key] === undefined) {
            delete object[key];
        }
    }
    return object;
};

export const range = (start, end, step) =>
    Array.from(Array.from(Array(Math.ceil((end - start) / step)).keys()), (x) => start + x * step);

export const _delete = (obj: { [x: string]: any }, prop: string) => {
    if (obj[prop] && !obj[prop].length) delete obj[prop];
};

export const download = (filename: string, text: string) => {
    const element = document.createElement('a');

    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
};

export const generateQRCode = (url: string, name: string) => {
    QRCode.toDataURL(url, { scale: 8, margin: 0, version: 4 }).then((data) => {
        const a = document.createElement('a');
        a.href = data;
        a.download = `${name}.png`;
        a.click();
    });
};
